import React from 'react'
import '../../Components/EventProgrames/EventProgrames.css'
// import ConstituencyData from '../../ConstituencyData/ConstituencyData'
import AllSocialmedia from '../SocialmediaShow/AllSocialmedia'
import VoteforEmployment from '../HomeComponents/VoteforEmployment/VoteforEmployment'
import ProposalToDM from '../HomeComponents/ProposalToDM/ProposalToDM'
import OpenMemoToMP from '../HomeComponents/OpenMemoToMP/OpenMemoToMP'
import ManOnMission from '../HomeComponents/ManOnMission/ManOnMission'
import EmploymentDebate from '../HomeComponents/EmploymentDebate/EmploymentDebate'
import MissionVNS from '../HomeComponents/MissionVNS/MissionVNS'
import ImageSlider from '../ImageSlider/ImageSlider'
import Onload_popup from '../Onload_popup/Onload_popup'

const EventProgrames = () => {


    return (
            <>
            <div className="my-4">
            <Onload_popup/>
            </div>
            <div className="my-4">
                <ImageSlider/>
            </div>
            <div className="my-4">
                <OpenMemoToMP />
            </div>
            <div className="my-4 p-3" style={{ background: "rgb(254 242 242)" }}>
                <ProposalToDM />
            </div>
            <div className="my-4">
                <MissionVNS />
            </div>
            <div className="my-4 p-3" style={{ background: "rgb(254 242 242)" }}>
                <EmploymentDebate />
            </div>
            <div className="my-4">
                <VoteforEmployment />
            </div>
            <div className="my-4 p-3" style={{ background: "rgb(254 242 242)" }}>
                <ManOnMission />
            </div>
            <div className="my-4">
                <AllSocialmedia />
            </div>
            
        </>
    )
}

export default EventProgrames
